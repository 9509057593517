<script lang="ts" setup>
import type { Props } from './types'
import { SCREEN } from '@/utils/segment'
import OnboardingFiltersBar from '@/components/OnboardingContent/FiltersBar/index.vue'
import { useThemeStore } from '@/stores/useThemeStore'

const themeStore = useThemeStore()

withDefaults(defineProps<Props>(), {
  title: ''
})

const { t } = useI18n()

</script>

<script lang="ts">
export default {
  name: 'SearchResultsContentHeader'
}
</script>

<template>
  <div>
    <div
      :class="{
        'bg-pine-600': themeStore.isV2,
        'bg-white md:mt-12 md:mb-16': !themeStore.isV2
      }">
      <OnboardingFiltersBar
        class="max-w-[1800px] md:px-40 py-24 mx-auto z-20 mt-0 pr-24 md:pr-36"
        :screen="SCREEN.SEARCH_RESULTS">
        <template
          v-if="(enableTitle || title) && !themeStore.isV2"
          #title>
          <div class="px-24 md:px-0 max-w-[1800px] mx-auto">
            <h1
              v-if="title"
              class="search-results-header__title--custom text-20 md:text-32 text-carbon-800 font-semibold mb-4">
              {{ title }}
            </h1>

            <h1
              v-else
              v-sanitize="t('title')"
              class="search-results-header__title--default text-20 md:text-32 text-carbon-800 font-semibold mb-4" />
          </div>
        </template>
      </OnboardingFiltersBar>
    </div>
  </div>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
