<script lang="ts" setup>
import { computed, ref } from 'vue'
import IconRoom from '@lahaus-roomie/roomie/src/assets/icons/v2/room.svg'
import IconLocked from '@lahaus-roomie/roomie/src/assets/icons/v2/locked.svg'
import type { Props } from './types'
import UserIdentityContainer from '@/components/App/UserIdentityContainer/index.vue'
import { LazyAppModal } from '#components'
import './_index.scss'

import { getDeliveryTermLabel, getBedroomsMin } from '@/utils/project'

const { t, n } = useI18n()
const props = withDefaults(defineProps<Props>(), {
  coverUrls: () => [],
  screen: 'onboarding'
})

const heroImages = computed(() => props.coverUrls.map((url: string) => ({ src: url, alt: t('cover_of', { name: props.name }) })))

const bedroomsLabel = computed(() => getBedroomsMin(props.typologies))

useHead({ link: [{ rel: 'preload', href: heroImages.value[0].src + '&w=400', as: 'image' }] })

const isRegistrationModalOpen = ref(false)

const closeRegistrationModal = () => {
  isRegistrationModalOpen.value = false
}

const clickHandler = () => {
  if (props.isContentLocked) {
    isRegistrationModalOpen.value = true

    return
  }

  navigateTo(props.path, {
    open: {
      target: '_blank'
    }
  })
}
</script>

<script lang="ts">
export default {
  name: 'FeatureProjectCard'
}
</script>

<template>
  <div
    class="feature-project cursor-pointer"
    :data-lh-id="`${screen}-hero-go-to-pdp`"
    @click="clickHandler">
    <Teleport to="body">
      <LazyAppModal
        v-if="isRegistrationModalOpen"
        class="lock-overlay__registration-modal !fixed top-0 z-60"
        @close="closeRegistrationModal">
        <UserIdentityContainer
          :id="`${screen}-user-identity-container`"
          :listing-id="code"
          :screen="screen"
          @submit="() => { isRegistrationModalOpen = false }" />
      </LazyAppModal>
    </Teleport>

    <div
      :class="{ authenticated: !isContentLocked }"
      class="feature-project__background-container grid grid-cols-1 md:grid-cols-3 grid-rows-2 md:gap-8 pointer-events-none">
      <picture
        v-for="(imgObj, index) in heroImages.slice(0, 3)"
        :key="imgObj.src"
        :class="{
          'md:col-start-2 row-span-2': index == 0,
          'hidden md:block col-start-3': [1, 2].includes(index)
        }">
        <source
          media="(max-width: 480px)"
          :srcset="`${imgObj.src}&w=400&h=400&fit=crop`">

        <source
          v-if="index == 0"
          media="(min-width: 481px) and (max-width: 799px)"
          :srcset="`${imgObj.src}&w=480&h=480&fit=crop`">

        <source
          v-if="index == 0"
          media="(min-width: 800px)"
          :srcset="`${imgObj.src}&w=800`">

        <img
          class="feature-project__background object-cover"
          :class="{
            '': index == 0,
            'rounded-tr-[16px]': index == 1,
            'rounded-br-[16px]': index == 2
          }"
          :loading="index == 0 ? 'eager' : 'lazy'"
          :src="imgObj.src+'&w=400&h=400&fit=crop'"
          :alt="imgObj.alt">
      </picture>
    </div>

    <div class="feature-project__container flex pointer-events-none grid grid-cols-1 md:grid-cols-3  grid-rows-[auto_1fr] md:grid-rows-2 md:gap-8">
      <div class="feature-project__content bg-gray2-400 relative p-24 md:p-32 row-span-1 md:row-span-2 flex flex-col">
        <div class="flex gap-8 align-center">
          <p
            class="font-semibold text-14 md:text-16 text-pine-600 bg-lime-600 py-8 px-16 inline-block rounded-sm mb-8 md:mb-0">
            <span>{{ getDeliveryTermLabel(props.deliveryDate, t) }}</span>
          </p>

          <p
            class="items-center font-semibold text-14 md:text-16 inline-flex gap-8 text-pine-600 bg-white py-8 px-16 rounded-sm mb-8 md:mb-0">
            <IconRoom class="w-24 h-24" />

            {{ bedroomsLabel }}
          </p>
        </div>

        <div
          class="feature-project__headings md:flex-1"
          :class="{ 'mb-8 md:mb-32': name, 'mt-4 md:my-24': !name }">
          <h2 class="hl-1-sb md:display-4 my-lh-8 mb-24 md:mb-0 text-pine-600 md:mt-32">
            <span v-if="!isContentLocked">
              {{ name }}
            </span>

            <span v-else>
              {{ neighborhood }}, {{ city }}
            </span>
          </h2>

          <p
            v-if="!isContentLocked"
            class="body-1-sb md:hl-3-m md:my-lh-8 text-pine-600">
            {{ neighborhood }}, {{ city }}
          </p>

          <div
            v-if="isContentLocked"
            class="feature-project__details flex flex-col mt-16">
            <span class="body-1-sb text-pine-600">
              {{ t('prices_from') }}
            </span>

            <span class="body-1-sb md:hl-3-m text-pine-600">
              ${{ n(minPrice) }} {{ currency }}
            </span>
          </div>
        </div>

        <div
          v-if="!isContentLocked"
          class="feature-project__details flex gap-4 md:justify-between">
          <span class="body-1-sb md:hl-3-m text-pine-600">
            {{ t('prices_from') }}
          </span>

          <span class="body-1-sb md:hl-3-m text-pine-600">
            ${{ n(minPrice) }} {{ currency }}
          </span>
        </div>

        <div
          v-else
          class="feature-project__details flex gap-8 items-center">
          <IconLocked class="text-pine-600" />

          <span class="body-1-sb md:hl-3-m text-pine-600">
            <span class="underline">
              {{ t('ctaBlocked_register') }}
            </span>
            {{ t('ctaBlocked_text') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
