import type { SearchFilters } from '@/components/SearchFilters/types'
import { areaFilter, searchFilter, idsFilter, priceFilter, bedroomsFilter, citySlugFilter, deliveryTermFilter, neighborhoodsFilter, neighborhoodSlugFilter, zoneSlugFilter, parkingLotsFilter, bathroomsFilter } from '@/composables/useOnboarding/filters'
import SearchService from '@/composables/useOnboarding/services/SearchService/v1'
import { isTruthyFilter } from '@/composables/useOnboarding/utils'
import type { LocationParams } from '@/composables/useListingsSearch/adapters/SearchServiceAdapter/types'
import type { LocationsQueryUtils } from '@/stores/locationsStore/types'
import type { Filter } from '@/composables/useOnboarding/types'

export default class SearchServiceAdapter {
  private readonly searchService: SearchService
  private readonly locationsQueryUtils: LocationsQueryUtils

  constructor (config: {
    $sobreplanosDataService: any,
    locationsQueryUtils: LocationsQueryUtils,
    customTitle?: string
  }) {
    this.searchService = new SearchService(config)
    this.locationsQueryUtils = config.locationsQueryUtils
  }

  async search (filters: SearchFilters, locationParams?: LocationParams) {
    const parsedFilters = this.parseFilters(filters, locationParams)

    return await this.searchService.search(parsedFilters)
  }

  parseFilters (filters: SearchFilters, locationParams?: LocationParams) {
    const parsedFilters: Filter[] = [
      {
        key: 'delivery_term',
        type: 'frontend',
        value: filters.deliveryTerm,
        filter: deliveryTermFilter
      },
      {
        key: 'bedrooms_number',
        type: 'frontend',
        value: filters.bedroomsNumber,
        filter: bedroomsFilter
      },
      {
        key: 'parking_lots_number',
        type: 'frontend',
        value: filters.parkingLotsNumber,
        filter: parkingLotsFilter
      },
      {
        key: 'bathrooms_number',
        type: 'frontend',
        value: filters.bathroomsNumber,
        filter: bathroomsFilter
      },
      {
        key: 'price_range',
        type: 'frontend',
        value: filters.price,
        filter: priceFilter
      },
      {
        key: 'area_range',
        type: 'frontend',
        value: filters.area,
        filter: areaFilter
      },
      {
        key: 'search',
        type: 'frontend',
        value: filters.q,
        filter: searchFilter
      },
      {
        key: 'ids',
        type: 'frontend',
        value: filters.ids,
        filter: idsFilter
      }
    ]

    const isThereAnyLocationParam = locationParams && Object.values(locationParams).some(Boolean)
    const isThereLocationFilter = filters.location && (filters.location.city || filters.location.zone || filters.location.neighborhood)

    if (filters.neighborhoods.length) {
      parsedFilters.push({
        key: 'neighborhoods',
        type: 'frontend',
        value: filters.neighborhoods,
        filter: neighborhoodsFilter
      },
      {
        key: 'business_hubs',
        type: 'backend',
        value: this.locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(filters.neighborhoods).map(({ name }) => name)
      })
    } else if (isThereLocationFilter) {
      if (filters.location.neighborhood) {
        const neighborhoodSlug = filters.location.neighborhood
        parsedFilters.push({
          key: 'neighborhood_slug',
          type: 'frontend',
          value: neighborhoodSlug,
          filter: neighborhoodSlugFilter
        })
      } else if (filters.location.zone) {
        const zoneSlug = filters.location.zone
        parsedFilters.push({
          key: 'zone_slug',
          type: 'frontend',
          value: zoneSlug,
          filter: zoneSlugFilter
        })
      } else {
        const citySlug = filters.location.city
        parsedFilters.push({
          key: 'city_slug',
          type: 'frontend',
          value: citySlug,
          filter: citySlugFilter
        })
      }
    } else if (isThereAnyLocationParam) {
      parsedFilters.push(
        {
          key: 'neighborhood_slug',
          type: 'frontend',
          value: locationParams.neighborhoodSlug,
          filter: neighborhoodSlugFilter
        },
        {
          key: 'zone_slug',
          type: 'frontend',
          value: locationParams.zoneSlug,
          filter: zoneSlugFilter
        },
        {
          key: 'city_slug',
          type: 'frontend',
          value: locationParams.citySlug,
          filter: citySlugFilter
        },
        {
          key: 'business_hubs',
          type: 'backend',
          value: Array.from(new Set(this.locationsQueryUtils.getAllAvailableBusinessHubs().map(({ name }) => name)))
        }
      )
    } else if (filters.businessHubs.length) {
      const businessHubs = this.locationsQueryUtils.getBusinessHubsByValues(filters.businessHubs)

      parsedFilters.push({
        key: 'neighborhoods',
        type: 'frontend',
        value: businessHubs.map(({ value }) => this.locationsQueryUtils.getNeighborhoodsFromBusinessHubValue(value).map(neighborhood => neighborhood.value)).flat(),
        filter: neighborhoodsFilter
      },
      {
        key: 'business_hubs',
        type: 'backend',
        value: businessHubs.map(({ name }) => name)
      })
    }

    return parsedFilters.filter(isTruthyFilter)
  }
}
