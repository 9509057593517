<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import IconClose from '@lahaus-roomie/roomie/src/assets/icons/close.svg'
import { SCREEN } from '@/utils/segment'
import { useLocationsStore } from '@/stores/locationsStore'
import { useUserStore } from '@/stores/userStore'
import useListingsSearch from '@/composables/useListingsSearch'
import useSearchResultsMetadata from '@/composables/useSearchResultsMetadata'
import type { InvestmentProjectSummary } from '@/server/api/buyer-front/types'
import OnboardingFiltersBar from '@/components/OnboardingContent/FiltersBar/index.vue'
import SearchResultsContentHeader from '@/components/SearchResultsContent/Header/index.vue'
import OnboardingEmptyResultsBanner from '@/components/OnboardingContent/EmptyResultsBanner/index.vue'
import SearchResultsContentHero from '@/components/SearchResultsContent/Hero/index.vue'
import OnboardingResultsCarousels from '@/components/OnboardingContent/ResultsCarousels/index.vue'
import OnboardingStepsLoader from '@/components/BrandedV2/OnboardingSteps/Loader/index.vue'
import { parseMetadata } from '@/composables/useSearchResultsMetadata/utils'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import { useThemeStore } from '@/stores/useThemeStore'

import './_index.scss'

const themeStore = useThemeStore()

const searchFiltersStore = useSearchFiltersStore()

const { t, locale } = useI18n()
const route = useRoute()
const locationsStore = useLocationsStore()
const userStore = useUserStore()

await callOnce(async () => {
  if (locationsStore.areLocationsLoaded) return

  await locationsStore.initializeLocations()
})

const { getMetadata } = useSearchResultsMetadata()

const { data: metadataData } = await useAsyncData<SeoMetadata>(`seometadata/${route.path}`, async () => {
  return await getMetadata()
})

const { baseMetadata = {}, customSrpOptions = {} } = metadataData.value || {}

const { search, isLoading, results, emitListingsSearchedEvent } = useListingsSearch({
  screen: SCREEN.INVESTMENT_SIMULATOR,
  ...customSrpOptions
})

const { data: initialResults } = await useAsyncData<SearchResults>('listings-search', async () => {
  return await search()
})

results.value = initialResults.value

const totalResultsCount = computed(() => {
  if (!results.value) return 0

  const exactMatchResultsCount = results.value.exactMatchProjects.length
  const extendedSearchResultsCount = results.value.extendedSearch.map(result => result.projects).flat().length
  const extraResultsCount = results.value.extraResults.map(result => result.projects).flat().length

  return exactMatchResultsCount + extendedSearchResultsCount + extraResultsCount
})

const metadata = computed(() => {
  return parseMetadata(
    {
      metadata: baseMetadata || {},
      resultsCount: totalResultsCount.value
    },
    { t, locale: locale.value }
  )
})

useSeoMeta(metadata.value)
useHead({ link: [{ rel: 'canonical', href: metadata.value?.canonicalUrl || route.path }] })

const { y } = useWindowScroll()

const outstandingProject = computed(() => results.value?.outstandingProject?.code ? results.value.outstandingProject : null)

const isHeroVisible = computed(() => !!outstandingProject.value?.code)

const canShowFloatingFiltersBar = computed(() => y.value > 170)

const isContentLocked = computed(() => !userStore.isAuthenticated)

const isRegistrationBannerOpen = ref(true)

const registrationBannerClicked = () => {
  const onboardingHero = document.querySelector('.onboarding-v2__hero')
  onboardingHero?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
}

onMounted(() => {
  emitListingsSearchedEvent({ userAction: 'DEFAULT_FILTERS' })

  const appHeader = document.querySelector('.app-header')
  if (appHeader) {
    appHeader.setAttribute('data-position', 'absolute')
  }
})

onUnmounted(() => {
  const appHeader = document.querySelector('.app-header')
  if (appHeader) {
    appHeader.removeAttribute('data-position')
  }
})
</script>

<script lang="ts">
export default {
  name: 'SearchResultsContent'
}
</script>

<template>
  <div
    class="search-results-page"
    :class="{
      'mt-60 md:mt-28': themeStore.isV2
    }">
    <OnboardingStepsLoader
      v-if="isLoading && themeStore.isV2" />

    <Transition name="slide-down">
      <div
        v-show="canShowFloatingFiltersBar"
        :class="{
          'bg-pine-600': themeStore.isV2,
          'bg-white': !themeStore.isV2
        }"
        class="fixed w-full left-0 top-0 z-50">
        <OnboardingFiltersBar
          :placeholder="t('emptyFiltersPlaceholder')"
          :screen="SCREEN.SEARCH_RESULTS"
          :class="{
            'py-16 md:py-8': themeStore.isV2,
            'py-8': !themeStore.isV2
          }"
          class="max-w-[1800px] md:px-40 mx-auto md:mb-16 mt-0 md:mt-12 pr-24 md:pr-36" />
      </div>
    </transition>

    <SearchResultsContentHeader
      :title="metadata.titleH1"
      :enable-title="!!outstandingProject" />

    <div
      v-if="isContentLocked && isRegistrationBannerOpen && isHeroVisible && themeStore.isV2"
      class="flex justify-center w-full">
      <div class="flex-1 px-24 md:px-40 max-w-[1800px]">
        <div class="mt-24 md:mt-40 -mb-24 md:-mb-16 px-24 py-16 bg-pine-200 rounded-lg relative flex items-center justify-between">
          <span
            class="link-1-sb cursor-pointer"
            :data-lh-id="`${SCREEN.SEARCH_RESULTS}-registration-banner`"
            @click="registrationBannerClicked">
            {{ t('registrationBanner', { count: totalResultsCount }) }}
          </span>

          <span
            id="registration-banner-close"
            class="cursor-pointer"
            :data-lh-id="`${SCREEN.SEARCH_RESULTS}-registration-banner-close`"
            @click="isRegistrationBannerOpen = false">
            <IconClose class="w-24 h-24" />
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="themeStore.isV2"
      class="bg-white px-24 max-w-[1800px] md:px-40 pb-24 md:pb-32 pt-48 md:pt-56 mx-auto">
      <h1
        v-if="metadata.titleH1"
        class="search-results-header__title--custom display-7 md:display-6 text-pine-600">
        {{ metadata.titleH1 }}
      </h1>

      <h1
        v-else
        class="search-results-header__title--default display-7 md:display-6 text-pine-600">
        {{ t('title', { count: searchFiltersStore.filterCount }) }}
      </h1>

      <p
        class="body-1-m text-pine-600 mt-4">
        {{ t('disclaimer') }}
      </p>
    </div>

    <div
      v-if="isHeroVisible"
      class="max-w-[1800px] mx-auto px-0 md:px-40"
      :class="{ 'mb-40': !isContentLocked, 'mb-32': isContentLocked }">
      <SearchResultsContentHero
        :screen="SCREEN.SEARCH_RESULTS"
        class="onboarding-v2__hero"
        :class="{ 'blur-sm': isLoading }"
        :listing="outstandingProject as InvestmentProjectSummary"
        :hide-title="isContentLocked" />
    </div>

    <OnboardingEmptyResultsBanner
      v-if="!outstandingProject"
      :screen="SCREEN.SEARCH_RESULTS"
      class="search-results-page__empty-results-banner max-w-[1800px] mx-auto" />

    <OnboardingResultsCarousels
      v-if="results"
      class="max-w-[1800px] mx-auto"
      :class="{ 'blur-sm': isLoading, 'mb-40': !isContentLocked, 'pt-24 mb-0': isContentLocked }"
      :exact-match-projects="results.exactMatchProjects"
      :extended-search="results.extendedSearch"
      :extra-results="results.extraResults"
      :screen="SCREEN.SEARCH_RESULTS"
      :is-content-locked="isContentLocked"
      :is-loading />
  </div>
</template>

<i18n src="./i18n.json" />

<style lang="scss">
  .roomie-footer {
    z-index: 0;

    &__navigation-title {
      @apply font-bold;
    }
  }
</style>
